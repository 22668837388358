import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationList,
  notificationActions,
} from "store/slices/notifications";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NotificationItem from "./NotificationItem";
import SectionHeader from "./SectionHeader";
import { NoDataScreen } from "./NoDataScreen";
import { notificationSelector, useAppThunkDispatch } from "store";
import { NotificationDto } from "api/notification";
import { InfiniteScroll } from "widgets/ChatWidget/components";
import { SkeletonContainer } from "components";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0",
    height: "82vh",
  },
}));

const Notifications: FC = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const classes = useStyles();
  const { notifications, isLoading } = useSelector(notificationSelector);
  const [page, setPage] = useState(-1);
  const [isAll, setIsAll] = useState(false);
  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);
  const dates = Object.keys(notifications);

  const loadMore = () => {
    const previousPage = page - 1;
    if (!isAll && page !== 0 && previousPage >= -1) {
      setPage(previousPage);
      thunkDispatch(getNotificationList({ page: previousPage, size: 10 }))
        .unwrap()
        // @ts-ignore
        .then((res) => setIsAll(!res.notifications.length || !res.page));
    }
  };

  const renderList = (items: NotificationDto[]) => {
    const uniqueItems = Array.from(
      new Map(items.map((item) => [item.id, item])).values()
    );

    return uniqueItems.map((el) => (
      <NotificationItem
        key={el.id}
        title={el.subTitle}
        date={el.notificationDateTime}
        status={el.notificationStatus}
        regNum={el.title}
      />
    ));
  };

  const getNotifications = () => {
    if (page >= -1) {
      thunkDispatch(getNotificationList({ page, size: 10, withLoader: true }))
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res.page !== 0 && res.page - 1 >= -1) {
            // @ts-ignore
            dispatch(getNotificationList({ page: res.page - 1, size: 10 }));
            // @ts-ignore
            setPage(res.page - 1 || 0);
          } else {
            setPage(0);
          }
        });
    }
  };

  useEffect(() => {
    getNotifications();
    dispatch(notificationActions.setReadNotifications());

    return () => {
      dispatch(notificationActions.clearNotifications());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box className={classes.box}>
      <SkeletonContainer height="100%" isLoading={isLoading} width="100%">
        {dates.length ? (
          <InfiniteScroll
            lastBlockRef={lastBlockRef}
            rootSentinelRef={rootSentinelRef}
            onLoadMore={loadMore}
            reverse
          >
            {dates.map((date) => (
              <Fragment key={date}>
                <SectionHeader date={date} />
                {renderList(notifications[date])}
              </Fragment>
            ))}
          </InfiniteScroll>
        ) : (
          <NoDataScreen title="У вас нет уведомлений" />
        )}
      </SkeletonContainer>
    </Box>
  );
};

export default Notifications;
