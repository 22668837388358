import React from "react";
import { Box, Typography } from "@mui/material";
import IconWrapper from "components/IconWrapper/IconWrapper";
import { formatAmount } from "utils/formatCurrency";
import { IconCardDropDown } from "atoms/IconCardDropDown";
import { getCardIcon } from "utils/card";
import { CARD_STATUS } from "components/CardsInfo/model";
import { CardDto } from "api/account";

interface CardDetailsProps {
  card: CardDto | undefined;
  hasMultipleCards: boolean;
  onClick?: () => void;
}

export const CardDetails: React.FC<CardDetailsProps> = ({
  card,
  hasMultipleCards,
  onClick,
}) => {
  const isBlockedCard = CARD_STATUS.Blocked.includes(card?.status as string);

  const handleClick = () => {
    if (onClick && card) {
      onClick();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "12px 16px",
        borderRadius: "15px",
        boxSizing: "border-box",
        backgroundColor: "var(--main-color-bg-widgets)",
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: "flex", columnGap: "12px" }}>
        <IconWrapper
          bgColor={isBlockedCard ? "var(--error-color-notification)" : ""}
        >
          {getCardIcon(card)}
        </IconWrapper>

        <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
          {card && (
            <>
              <Typography
                noWrap
                sx={{
                  color: "var(--main-color-text-title)",
                  fontWeight: 500,
                  fontSize: "18px",
                }}
              >
                {card.paymentNetwork
                  ? card.paymentNetwork === "MIR"
                    ? "МИР"
                    : card.paymentNetwork
                  : "МИР"}
              </Typography>
              <Box
                sx={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "100px",
                  backgroundColor: "var(--main-color-text-secondary)",
                }}
              />
            </>
          )}

          <Typography
            noWrap
            sx={{
              color: "var(--main-color-text-secondary)",
              fontWeight: 500,
              fontSize: "18px",
            }}
          >
            {card ? card?.cardPan?.slice(-4) : "Нет доступных карт"}
          </Typography>
        </Box>
      </Box>

      {card && (
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "16px" }}>
          <Typography
            noWrap
            sx={{
              color: "var(--main-color-text-title)",
              fontWeight: 500,
              fontSize: "18px",
            }}
          >
            {formatAmount(card?.amount, card?.currency?.sign)}
          </Typography>

          {hasMultipleCards && <IconCardDropDown />}
        </Box>
      )}
    </Box>
  );
};
