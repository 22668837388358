import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme, Typography, Chip } from "@mui/material";
import { statuses } from "./constants";
import { format } from "date-fns";
import { IconNotificationItem } from "atoms/IconNotificationItem/IconNotificationItem";

interface INotificationItem {
  title?: string;
  date?: string;
  status?: string;
  regNum?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  green: {
    color: "var(--main-color-text-title)",
    borderColor: "transparent",
    backgroundColor: "var(--success-color-icon)",
    height: "24px",
  },
  red: {
    color: "var(--main-color-text-title)",
    borderColor: "transparent",
    backgroundColor: "var(--error-color-notification)",
    height: "24px",
  },
  orange: {
    color: "var(--main-color-text-title)",
    borderColor: "transparent",
    backgroundColor: "var(--secondary-color-8)",
    height: "24px",
  },
  default: {
    color: "var(--main-color-text-title)",
    borderColor: "transparent",
    backgroundColor: "var(--secondary-color-8)",
    height: "24px",
  },
  icon: {
    flexShrink: 0,
  },
}));

const NotificationItem: FC<INotificationItem> = ({
  title,
  date,
  status,
  regNum,
}) => {
  const classes = useStyles();
  const statusInfo = statuses[status || ""];
  const time = date ? format(new Date(date), "HH:mm") : "";

  const statusColor = classes[statusInfo?.color];

  return (
    <Box display="flex" gap={12} py={12}>
      <IconNotificationItem className={classes.icon} />
      <Box>
        <Box
          mb={8}
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          {!!statusInfo && (
            <Box display="flex" gap={8} alignItems="center">
              <Chip
                className={statusColor}
                label={statusInfo?.label}
                variant="outlined"
              />
              {regNum && (
                <Box display="flex" gap={8} alignItems="center">
                  <div
                    style={{
                      width: "3px",
                      height: "3px",
                      borderRadius: "4px",
                      backgroundColor: "var(--main-color-text-subtitle)",
                    }}
                  />
                  <Typography
                    fontSize={14}
                    lineHeight="20px"
                    fontWeight={400}
                    color="var(--main-color-text-subtitle)"
                  >
                    Заявка {regNum}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          <Typography
            fontSize={13}
            lineHeight="14px"
            fontWeight={400}
            color="var(--main-color-notification-description)"
          >
            {time}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            fontSize={16}
            lineHeight="20px"
            fontWeight={400}
            color="var(--main-color-text-title)"
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
