import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { formatAmount } from "utils/formatCurrency";
import miniCardBG from "images/miniCardBG.png";
import miniCardBlocked from "images/miniCardBlocked.png";
import miniCardFrozen from "images/miniCardFrozen.png";
import { useDispatch } from "react-redux";
import {
  setIsAccountInfoOpen,
  setIsCardInfoOpen,
} from "store/slices/cardsSlice";
import { CARD_STATUS } from "components/CardsInfo/model";

import { useIsPWA } from "hooks/useIsPWA";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { Card } from "./Card";

const CardInfoItemContainer = styled(Box)({
  display: "flex",
  columnGap: "9px",
  alignItems: "center",
  cursor: "pointer",
});

const CardMiniature = styled("img")({
  width: "78px",
  height: "48px",
  borderRadius: "6px",
});

const ArrowContainer = styled("div")({
  position: "absolute",
  right: "15px",
  top: "28px",
});

/**
 * TO DO: градиент
 */

export const ConnectedCards = ({ cards }: { cards: any }) => {
  const dispatch = useDispatch();
  const openCard = (index: any) => {
    dispatch(setIsAccountInfoOpen(index));
    dispatch(setIsCardInfoOpen(index));
  };
  const isPWA = useIsPWA();
  return (
    <Box mt={18}>
      <Typography
        sx={{
          color: "#454A3F",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
        mb={14}
      >
        Привязанные карты
      </Typography>
      <Box>
        <CardInfoItemContainer>
          {cards.map((card, index) => {
            return (
              <div onClick={() => openCard(index)} key={index}>
                <Card card={card} />
              </div>
            );
          })}
        </CardInfoItemContainer>
      </Box>
    </Box>
  );
};
